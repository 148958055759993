<template>
	<main>
		<HeaderTab :title="$t('client_area.unacknowledged_horses')" />
        <div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="box">
                            <CustomTable 
								ref="table"
								id_table="unacknowledged_horses_client_area"
								primaryKey="tiershorse_id"
								sortBy="horse.horse_nom"
                                :items="items"
                                :busy.sync="table_busy"
								:hrefsRoutes="config_table_hrefs"
                            />
                        </div>
                    </div>
                </div>
		    </div>
		</div>
	</main>
</template>


<script type="text/javascript">
	import { EventBus } from 'EventBus'
    import Horse from "@/mixins/Horse.js"
    import Navigation from "@/mixins/Navigation.js"

	export default {
		name: "HorseUnacknowledgedClientArea",
		mixins: [Horse, Navigation],
		data () {
			return {
                items: [],
				table_busy: true,
				events_tab: {
                    'TableAction::validHorses': this.validHorses
                },
				config_table_hrefs: {
                    'horse.horse_nom': {
                        routeUniqueName: 'horseFiche',
                        routeName: 'horseFiche',
                        params: {
                            horse_id: 'horse.horse_id'
                        }
                    },
					'tiers.tiers_rs': {
                        routeUniqueName: 'tiersFiche',
                        routeName: 'tiersFiche',
                        params: {
                            tiers_id: 'tiers.tiers_id'
                        }
                    }
				}
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
				this.reloadTable()
			},
			async reloadTable() {
				this.table_busy = true
				this.items = await this.getHorsesCaUnacknowledged()
				this.table_busy = false
			},
			async validHorses(tiershorse) {
				const ids = tiershorse.map(th => th.tiershorse_id)
				this.validUnacknowledgedHorseCa(ids)
					.then(() => {
						this.successToast()
						return this.reloadTable()
					})
					.then(() => {
						this.$refs.table.resetCachedItems()
		                EventBus.$emit("TableAction::stopSpin", "goToValidHorse")
					})
			}
		},
		components: {
			HeaderTab: () => import('@/components/HeaderTab'),
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
            ModalConfirm: () => import('GroomyRoot/components/Modals/ModalConfirm'),
		}
	}
</script>
